import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { colors, mediaMax, mediaMin, shadows, zIndex } from "utils/theme";
import { BodyLarge } from "components/ui/Text";
import Button from "components/ui/Button";
import Icon from "components/ui/Icon";
import { SubmitButton } from "components/form";

export const Wrapper = styled.div`
  padding: 0 24px 128px;

  ${mediaMax("md")} {
    padding: 0 0 128px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${mediaMax("md")} {
    padding: 24px 16px;
  }
`;

export const QuestionCarousel = styled.div`
  position: relative;
`;

export const QuestionWrapper = styled(motion.div)`
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${colors.violet};

  ${mediaMax("md")} {
    color: white;
  }
`;

export const Progress = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProgressDot = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 9999px;
  background: ${colors.heliotrope};
  opacity: 0.5;
`;

export const ProgressCompleted = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  width: 12px;
  height: 12px;

  border-radius: 9999px;
  background: ${colors.heliotrope};
`;

export const Question = styled(BodyLarge).attrs(() => ({ tag: "div" }))`
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: ${colors.midnight};

  * {
    margin: 0;
  }

  ${mediaMax("md")} {
    &,
    * {
      color: white;
    }
  }
`;

export const OptionsWrapper = styled.div`
  ${mediaMax("md")} {
    label {
      color: white;
      border: 1px solid color-mix(in srgb, ${colors.ice} 10%, transparent);
    }
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.nav};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92px;
  padding: 0;
  margin: 0;

  background: white;
  box-shadow: ${shadows.xlarge};
`;

export const FooterInner = styled.div`
  width: 100%;
  max-width: 656px;
  padding: 0 56px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  & > * {
    flex: 0 0 auto;
    width: calc(50% - 8px);
  }

  ${mediaMax("md")} {
    padding: 0 16px;
  }
`;

export const ButtonIcon = styled(Icon)`
  * {
    stroke-width: 20px;
    stroke: currentColor;
  }

  ${mediaMin("md")} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const BackButton = styled(Button)`
  position: relative;

  background: white;
  border: 1.5px solid ${colors.violet}40;
  color: ${colors.violet};

  ${ButtonIcon} {
    left: 16px;
  }

  ${mediaMax("md")} {
    width: auto;
    padding: 0 24px;
    border: 0;

    text-decoration: underline;

    ${ButtonIcon} {
      display: none;
    }
  }
`;

const nextButtonStyles = css`
  position: relative;

  ${ButtonIcon} {
    right: 16px;
  }

  ${mediaMax("md")} {
    flex: 1 1 auto;

    ${ButtonIcon} {
      margin-right: -16px;
    }
  }
`;

export const NextButton = styled(Button)`
  ${nextButtonStyles}
`;

export const FinishButton = styled(SubmitButton)`
  ${nextButtonStyles}
`;
