import React, { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import ResultsSection from "./ResultsSection";
import GetStartedSection from "./GetStartedSection";
import CardSection from "./CardSection";
import FeaturesSection from "./FeaturesSection";
import FooterSection from "./FooterSection";

interface Props {
  score: number;
  subscores: number[];
}

const Results: React.FC<Props> = ({ score, subscores }) => {
  const learnMoreRef = useRef(null);

  const scrollToLearnMore = (e) => {
    e.preventDefault();

    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.hash = "#learn-more";
    }
  };

  return (
    <div>
      <AnimatePresence initial>
        <ResultsSection
          score={score}
          subscores={subscores}
          scrollToLearnMore={scrollToLearnMore}
        />
      </AnimatePresence>

      <div ref={learnMoreRef} id="learn-more">
        <GetStartedSection score={score} subscores={subscores} />
      </div>

      <CardSection />

      <FeaturesSection />

      <FooterSection />
    </div>
  );
};

export default Results;
