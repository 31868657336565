import React from "react";
import waveImage from "assets/images/general/wave-shape.svg";
import { BodyLarge, Heading } from "components/ui/Text";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import heroImage from "assets/images/financial-health/intro.jpg";
import SiteNavigation from "components/ui/SiteNavigation";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

interface Props {
  getStarted: () => void;
}

const Intro: React.FC<Props> = ({ getStarted }) => (
  <Styled.Wrapper>
    <Styled.Navigation>
      <SiteNavigation />
    </Styled.Navigation>

    <Styled.Content>
      <Styled.ImageWrapper>
        <Styled.Image>
          <img src={heroImage} alt="Couple planning their finances" />
        </Styled.Image>
      </Styled.ImageWrapper>

      <Styled.Main>
        <Heading.H1>Unlock your financial health.</Heading.H1>
        <BodyLarge color="ice">
          The Financial Health Survey takes about 3 minutes and is completely
          anonymous. See your progress over time and how you compare to others!
        </BodyLarge>

        <Styled.GetStartedButton>
          <MobileFixedFooter>
            <Button
              size={ButtonSize.xl}
              level={ButtonLevel.cta}
              onClick={getStarted}
            >
              Get Started <Styled.ButtonIcon name="arrowRight" />
            </Button>
          </MobileFixedFooter>
        </Styled.GetStartedButton>
      </Styled.Main>
    </Styled.Content>

    <Styled.Shape>
      <img src={waveImage} />
    </Styled.Shape>
  </Styled.Wrapper>
);

export default Intro;
