import DisappearingTile from "components/ui/DisappearingTile";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, mediaMin, shadows } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  background: ${colors.midnight};
`;

export const StepWrapper = styled(motion.div)`
  left: 0;
  top: 0;

  min-height: 100vh;
  width: 100%;
`;

export const Tile = styled(DisappearingTile).attrs(() => ({ size: "xl" }))`
  max-width: 640px;
  margin: 0 auto;
  overflow: hidden;

  ${mediaMin("md")} {
    box-shadow: ${shadows.tileSolid};
  }
`;
