import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, Variants } from "framer-motion";
import { RadioField } from "components/form";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { isDefined } from "utils/object";
import { QUESTIONS, CATEGORIES } from "../constants";
import Header from "../Header";
import { Tile } from "../styled";
import * as Styled from "./styled";

const VARIANTS = {
  enter: (direction: number) => ({
    position: "absolute",
    zIndex: 1,
    x: `${direction * 100}%`,
    opacity: 0,
  }),
  center: {
    position: "relative",
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    position: "absolute",
    x: `${-direction * 100}%`,
    opacity: 0,
  }),
} as Variants;

const Questionnaire = () => {
  const { watch } = useFormContext();
  const subscores = watch("questions");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [transitionDirection, setTransitionDirection] = useState(-1);

  const isNextDisabled = !isDefined(
    subscores && subscores[currentQuestionIndex]
  );
  const { text, options, categoryName } = QUESTIONS[currentQuestionIndex];
  const category = CATEGORIES[categoryName];

  const updateTransitionDirection = async (direction: number) => {
    setTransitionDirection(direction);
    await setTimeout(() => {}, 0);
  };

  const goToPrevious = async () => {
    if (currentQuestionIndex === 0) return;

    await updateTransitionDirection(-1);
    setCurrentQuestionIndex((i) => i - 1);
  };

  const goToNext = async () => {
    await updateTransitionDirection(1);

    setCurrentQuestionIndex((i) => i + 1);
  };

  return (
    <Styled.Wrapper>
      <Header />

      <Tile>
        <Styled.ContentWrapper>
          <Styled.Header>
            <Styled.Category>
              <Icon name={category.icon} size="md" />

              <UILabel.Small bold wideCaps>
                {category.title}
              </UILabel.Small>
            </Styled.Category>

            <Styled.Progress>
              {QUESTIONS.map((_, questionIndex) => (
                <Styled.ProgressDot key={questionIndex} />
              ))}

              <Styled.ProgressCompleted
                animate={{
                  width: 12 * ((currentQuestionIndex + 1) * 2 - 1),
                }}
                initial={{ width: 12 }}
                transition={{
                  duration: 0.15,
                  ease: [0.25, 1, 0.5, 1],
                }}
              />
            </Styled.Progress>
          </Styled.Header>

          <Styled.QuestionCarousel>
            <AnimatePresence initial={false} mode="sync">
              <Styled.QuestionWrapper
                custom={transitionDirection}
                variants={VARIANTS}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                  duration: 0.2,
                }}
                key={currentQuestionIndex}
              >
                <Styled.Question>{text}</Styled.Question>

                <Styled.OptionsWrapper>
                  <RadioField
                    required
                    key={currentQuestionIndex}
                    name={`questions.${currentQuestionIndex}`}
                    options={options}
                  />
                </Styled.OptionsWrapper>
              </Styled.QuestionWrapper>
            </AnimatePresence>
          </Styled.QuestionCarousel>
        </Styled.ContentWrapper>
      </Tile>

      <Styled.Footer>
        <Styled.FooterInner>
          {currentQuestionIndex > 0 && (
            <Styled.BackButton
              type="button"
              level={ButtonLevel.ghost}
              onClick={goToPrevious}
              size={ButtonSize.xl}
            >
              <Styled.ButtonIcon name="arrowLeft" />
              Back
            </Styled.BackButton>
          )}

          {currentQuestionIndex < QUESTIONS.length - 1 ? (
            <Styled.NextButton
              type="button"
              level={ButtonLevel.cta}
              size={ButtonSize.xl}
              onClick={goToNext}
              disabled={isNextDisabled}
            >
              Next
              <Styled.ButtonIcon name="arrowRight" />
            </Styled.NextButton>
          ) : (
            <Styled.FinishButton size={ButtonSize.xl} disabled={isNextDisabled}>
              Finish
              <Styled.ButtonIcon name="arrowRight" />
            </Styled.FinishButton>
          )}
        </Styled.FooterInner>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

export default Questionnaire;
