import { RESULTS_CATEGORIES, SURVEY_SCORE } from "./constants";
import { QuestionnaireCategoryName } from "./types";

export const getResults = (subscores: number[]) => {
  const score =
    subscores.reduce((sum, value) => sum + value, 0) / subscores.length;

  return { score, subscores };
};

export const getInitialresults = () => {
  const params = new URLSearchParams(window.location.search);
  const subscoreString = params.get("ss") || "";
  const subscoreHexes = subscoreString.match(/.{1,2}/g); // each subscore is a 2-digit hex e.g. 0a or 4b

  if (subscoreHexes?.length !== 8) return null;

  const subscores = subscoreHexes.map((hex) => parseInt(hex, 16));
  const initialResults = getResults(subscores);
  return initialResults;
};

export const getScoresByCategory = (subscores: number[]) =>
  subscores.reduce(
    (obj, score, scoreIndex) => {
      // every 2 scores belong to the same category
      const categoryIndex = Math.floor(scoreIndex / 2);
      const category = RESULTS_CATEGORIES[categoryIndex];
      const categoryScore = obj[category] + score / 2;
      return { ...obj, [category]: categoryScore };
    },
    {
      [QuestionnaireCategoryName.Spending]: 0,
      [QuestionnaireCategoryName.Saving]: 0,
      [QuestionnaireCategoryName.Borrowing]: 0,
      [QuestionnaireCategoryName.Planning]: 0,
    }
  );

export const getScoreLabel = (score: number) => {
  if (score < SURVEY_SCORE.coping) {
    return "Working on It";
  }
  if (score < SURVEY_SCORE.healthy) {
    return "On Your Way";
  }
  return "Thriving";
};
